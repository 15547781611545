<template>
  <section>
    <breadcrumb :title="$t('nomenclators')" :subtitle="$t('payment_groups')"/>
    <div class="card px-10 pb-30 rounded-10">
      <div class="columns filters">
        <div class="column is-flex is-align-items-center">
          <div>
            <b-field>
              <b-input :placeholder="$t('search')"
                       custom-class="search-filter"
                       type="search"
                       icon="magnify"
                       icon-clickable
              >
              </b-input>
            </b-field>
          </div>
          <div class="ml-15">
            <b-icon type="is-pink-light" size="is-medium" icon="close-circle-outline"
                    class="mx-4 cursor-pointer"></b-icon>
          </div>
          <div @click="filter=!filter" class="ml-25">
            <b-icon type="is-purple" size="is-medium" :icon="filter_name"></b-icon>
          </div>
        </div>
        <div class="column is-flex is-justify-content-flex-end mr-10">
          <button @click="isModalPaymentGroupsActive= !isModalPaymentGroupsActive" class="button new is-pink-light">
            <span class="mdi mdi-plus"></span>
            {{ $t('group') }}
          </button>
        </div>
      </div>
    </div>

    <section class="pt-40">
      <b-table
          :data="desserts"
          ref="table"
          :paginated="true"
          :current-page="currentPage"
          :per-page="itemsPerPage"
          detailed
          :show-detail-icon="false"
          detail-key="id"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page">

        <b-table-column field="name" :label="$t('name')" v-slot="props">
          <template>
            <a class="col name" @click="props.toggleDetails(props.row)">
              {{ props.row.name }}
            </a>
          </template>
        </b-table-column>

        <b-table-column field="symbol" :label="$t('currency')" sortable v-slot="props">
       <span class="symbol">
         {{ props.row.currencyTo }}
       </span>
        </b-table-column>

        <b-table-column field="exchange_rate" :label="$t('percent')" sortable v-slot="props">
        <span class="col exchange_rate">
           {{ props.row.rate }}
        </span>
        </b-table-column>

        <b-table-column field="exchange_rate" :label="$t('amount_min')" sortable v-slot="props">
        <span class="col exchange_rate">
           {{ props.row.minimalAmount }}
        </span>
        </b-table-column>

        <b-table-column field="exchange_rate" :label="$t('utility_type')" sortable v-slot="props">
        <span class="col exchange_rate">
           {{ getUtilityType(props.row.utilityType) }}
        </span>
        </b-table-column>

        <b-table-column field="exchange_rate" :label="$t('fixed_utility')" sortable v-slot="props">
        <span class="col exchange_rate" v-if="props.row.utilityType === utilities.FIXED">
           {{ props.row.utilityValue }}
        </span>
        </b-table-column>

        <b-table-column field="exchange_rate" :label="$t('agencies')" sortable v-slot="props">
        <span class="col exchange_rate">
          {{ props.row.agenciesGroup.length }}
        </span>
        </b-table-column>

        <b-table-column field="commission" :label="$t('active')" sortable v-slot="props">
          <div class="data-check">
            <b-checkbox type="is-success" v-model="props.row.isActive"></b-checkbox>
          </div>
        </b-table-column>

        <b-table-column :label="$t('actions')" v-slot="props">
          <div class="is-flex">
            <div @click="editGroup(props.row)">
              <b-icon type="is-green-accent" icon="pencil" class="mx-4 cursor-pointer"></b-icon>
            </div>
            <div class="mx-10" @click="confirmCustomDelete(props.row.id)">
              <b-icon type="is-danger" icon="trash-can-outline" class="mx-4 cursor-pointer"></b-icon>
            </div>
          </div>

        </b-table-column>
        <template #pagination>
          <b-pagination
              :total="desserts.length"
              v-model="currentPage"
              :range-before="3"
              :range-after="1"
              order="is-right"
              @change="saveCurrentPage"
              :per-page="itemsPerPage"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page">
          </b-pagination>
        </template>
        <template #detail="props">
          <div class="columns">
            <div class="column is-3 is-flex is-flex-direction-column">
              <span class="details-text">{{ $t('id') }}</span>
              <span class="details-text">{{ $t('created') }}</span>
              <span class="details-text">{{ $t('updated') }}</span>
            </div>
            <div class="column is-8 is-flex is-flex-direction-column">
              <span class="details-data">{{ props.row.id }}</span>
              <span class="details-data">{{ props.row.created }}</span>
              <span class="details-data">{{ props.row.updated }}</span>
            </div>
          </div>

        </template>
      </b-table>

      <b-modal
          v-model="isModalPaymentGroupsActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="false"
          aria-role="dialog"
          aria-label="Example Modal"
          aria-modal>
        <template #default="props">
          <modals-forms-payments-group :edit="edit" :item="item" @reload="reload" @close="props.close"></modals-forms-payments-group>
        </template>
      </b-modal>
    </section>
  </section>
</template>

<script>
import Breadcrumb from "@/components/structure/breadcrumb";
import {mapActions, mapGetters} from "vuex";
import ModalsFormsPaymentsGroup from "@/components/modals/modalsFormsPaymentsGroup";
import {UTILITY_TYPE} from "@/enum/utilityType";
import {exitsKey, getStorage, notificationSuccess, saveStorage} from "@/utils";

export default {
  name: "PaymentGroupsComponent",
  components: {ModalsFormsPaymentsGroup, Breadcrumb},
  data() {
    return {
      filter_name: 'filter-outline',
      filter: false,
      edit: false,
      item: null,
      utilities:UTILITY_TYPE,
      isModalPaymentGroupsActive: false,
      propsModals: {},
      desserts: [],
      itemsPerPage: 10,
      currentPage: 1,
      totalPages: 0,
      totalRecords: 0,
    }
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANG'
    })
  },
  methods: {
    ...mapActions({
      fetchPaymentsGroup: 'FETCH_PAYMENTS_GROUPS',
      deletePg: 'DELETE_PAYMENT_GROUP'
    }),
    confirmCustomDelete(id) {
      this.$buefy.dialog.confirm({
        title: 'Eliminando Grupo de pago',
        message: 'Esta seguro que desea <b>eliminar</b> la moneda?',
        confirmText: 'Comfirmar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.deletePg(id)
              .then(() => {
                notificationSuccess('Grupo de pago eliminado');
                this.getPaymentsGroup(1, 10)
              })
        }
      })
    },
    saveCurrentPage() {
      saveStorage('currentPg', this.currentPage, false)
    },
    reload() {
      window.location.reload()
    },
    editGroup(prop) {
      this.item = prop
      this.edit = !this.edit
      this.isModalPaymentGroupsActive = !this.isModalPaymentGroupsActive
    },

    getUtilityType(type) {
      switch (type) {
        case UTILITY_TYPE.FIXED:
          return this.lang === 'es' ? 'Fija' : 'FIXED'
        case UTILITY_TYPE.PERCENTAGE:
          return this.lang === 'es' ? 'Porciento' : 'Percentage'
      }
    },
    getLast() {
      let page = this.desserts.totalRecords % this.desserts.itemsPerPage
      this.getCurrencies(page, this.desserts.itemsPerPage)
    },
    getPaymentsGroup(current, record, type = 0) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: null,
        type: type
      };
      return new Promise(resolve => {
        this.fetchPaymentsGroup(data).then(value => {
          resolve(value)
          if (exitsKey('currentPg')) {
            this.currentPage = Number(getStorage('currentPg', false))
          }
        })
      })

    },
  },
  created() {
    this.getPaymentsGroup(1, 10)
        .then(value => {
          this.desserts = value
        })
  }
}
</script>

<style scoped lang="scss">
.col {
  line-height: 32px;
  letter-spacing: 0.5px;

  &.name {
    font-weight: bold;
    font-size: 16px;
    color: #666BAA;
  }
}
.button {
  &.new {
    width: 150px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 20px;
    line-height: 180%;
    letter-spacing: 0.5px;
    color: #FFFFFF;
  }

  .mdi-plus {
    font-size: 38px;
  }
}
</style>